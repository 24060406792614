import axios from "./axios";

import {UNAUTHORIZED_HTTP_CODE} from "../../constants/common";
import { TOKEN_KEY, SNACK_ERROR } from '../../constants/common';
import { useSnackbar } from 'notistack';

// export const sendInvite = userInfo =>
//   new Promise((resolve, reject) => {
//     axios
//       .post("DidoUser/SendInvite", userInfo)
//       .then(response => {
//         console.log(response);
//         //if (response.status === 200)
//           resolve(response);
//       })
//       .catch(err => {
//         axios.defaults.headers.common["Authorization"] = "";
//         if (err?.errorCode === UNAUTHORIZED_HTTP_CODE) {
//           reject(err);
//         } else {
//           reject(new Error(err));
//         }
//       });
//   });
 
export const logger = {
  warning(eventName, object) {
    // if (localStorage.getItem('cfg.log.disabled') == 'true') {
    //   return;
    // }
    if (!localStorage.getItem(TOKEN_KEY)) {
      return;
    }

    axios.post("JsLog/warning?eventname=" + eventName, { url: window.location.href, text: JSON.stringify(object), time: new Date() })
  },

  error(eventName, object) {
    if (!localStorage.getItem(TOKEN_KEY)) {
      return;
    }

    axios.post("JsLog/error?eventname=" + eventName, { url: window.location.href, text: JSON.stringify(object), time: new Date() })
  },

  errorWithSnackbar(message, objectThis, error){

    if (objectThis != null && objectThis.props != null){
      objectThis.props.enqueueSnackbar(message, SNACK_ERROR);
    }

    if (!localStorage.getItem(TOKEN_KEY)) {
      return;
    }

    axios.post("JsLog/error?eventname=" + message, { url: window.location.href, text: ""+error, time: new Date() })

  }

}


export const sendInvite = (params) => axios.post("DidoUser/SendInvite", params);
export const signInByInvite = (params) => axios.post("DidoUser/SignInByInvite", params);
export const signUpUser = (params) => axios.post("DidoUser/SignUpUser", params);
export const getCurrentUser = () => axios.get("DidoUser/GetCurrentUser");
export const getUsers = () => axios.get("DidoUser/GetUsers");
export const getUsersByOrg = (guid) => axios.get("DidoUser/GetUsersByOrg", {params: {guid: guid}});
export const isEmailNotExists = (email) => axios.get("DidoUser/IsEmailNotExists",  { params: { email } });
export const createUser = (params) => axios.post("DidoUser/CreateUser", params);
export const createUserWithUpload = (params) => axios.post("DidoUser/CreateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data' } });
export const updateUser = (params) => axios.post("DidoUser/UpdateUser", params);
export const updateUserWithUpload = (params) => axios.post("DidoUser/UpdateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data' } });
export const deleteUser = (id) => axios.post("DidoUser/DeleteUser", null, { params: { deleteGuid: id } });

export const impersonalization = (params) => axios.post("DidoUser/Impersonalization", JSON.stringify(params), { headers: { 'Content-Type': 'application/json' } });
export const stopImpersonalization = () => axios.post("DidoUser/stopImpersonalization");


//DidoProfiles
export const getProfiles = () => axios.get("DidoProfile/GetProfiles");
export const createProfile = (params) => axios.post("DidoProfile/CreateProfile", params);
export const updateProfile = (params) => axios.post("DidoProfile/UpdateProfile", params);
export const deleteProfile = (id) => axios.post("DidoProfile/DeleteProfile", null, { params: { deleteGuid: id } });

export const getRoles = () => axios.get("Roles/GetRoles");

// Subscriptions
export const getSubscriptions = () => axios.get("Subscription/GetAll");
export const createSubscription = (params) => axios.post("Subscription/Create", params);
export const updateSubscription = (params) => axios.post("Subscription/Update", params);
export const deleteSubscription = (id) => axios.post("Subscription/Delete", null, { params: { guid: id } });

  // Organization
export const createOrganization = (params) => axios.post("Organization/Create", params);
export const getOrganizations = () => axios.get("Organization/GetAll");
export const getOrganizationsWithoutChilds = (orgGuid) => axios.get("Organization/GetAllWithoutChilds", { params: { orgGuid: orgGuid } })
export const getOrganizationsByDomain = (guid) => axios.get("Organization/GetAllByDomain", {params: {guid: guid}});
export const getOrganizationsByFilter = (filter) => axios.get("Organization/GetAllByFilter", {params: filter});
export const updateOrganization = (params) => axios.post("Organization/Update",params);
export const deleteOrganization = (guid) => axios.post("Organization/Delete", null, { params: { guid: guid } });
export const importFreemiumOrganizations = (params) => axios.post("Organization/ImportFreemiumOrganizations", params, { headers: { 'Content-Type': 'multipart/form-data' } });
export const getOrganizationsForSubscriptionPage = (namePattern) => axios.get("Organization/GetOrganizationsForSubscriptionPage",{ params: { namePattern: namePattern } });

// Location
export const createLocation = (params) => axios.post("Location/Create", params);
export const getLocations = () => axios.get("Location/GetAll");
export const getLocationsWithoutChilds = (orgGuid) => axios.get("Location/GetAllWithoutChilds", { params: { orgGuid: orgGuid } })
export const updateLocation = (params) => axios.post("Location/Update", params);
export const deleteLocation = (guid) => axios.post("Location/Delete", null, { params: { guid: guid } });

// Team
export const createTeam = (params) => axios.post("Team/Create", params);
export const createTeamWithUpload = (params) => axios.post("Team/CreateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data'}});
export const getTeams = () => axios.get("Team/GetAll");
export const getTeamsByLocation = (locationGuid) => axios.get("Team/GetTeamsByLocation", { params: { locationGuid: locationGuid } });
export const getTeamsByOrg = (guid) => axios.get("Team/GetTeamsByOrg", { params: { guid: guid } });
//export const getTeamsForCurrentUser = () => axios.get("Team/GetTeamsForCurrentUser");
export const updateTeam = (params) => axios.post("Team/Update", params);
export const updateTeamWithUpload = (params) => axios.post("Team/UpdateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data'}});
export const deleteTeam = (guid) => axios.post("Team/Delete", null, { params: { guid: guid } });

// Unit
export const createUnit = (params) => axios.post("Unit/Create", params);
export const getUnits = () => axios.get("Unit/GetAll");
export const getUnitsByTeam = (guid) => axios.get("Unit/GetAllByTeam", {params: {guid: guid}});
export const getByTeamWithZpAsync = (guid) => axios.get("Unit/GetByTeamWithZp", {params: {guid: guid}});
export const updateUnit = (params) => axios.post("Unit/Update", params);
export const deleteUnit = (guid) => axios.post("Unit/Delete", null, { params: { guid: guid } });

  // Domain
export const createDomain = (params) => axios.post("Domain/Create", params);
export const getDomains = () => axios.get("Domain/GetAll");
export const getDomainsWithoutChilds = () => axios.get("Domain/GetAllWithoutChilds");
export const updateDomain = (params) => axios.post("Domain/Update",params);
export const deleteDomain = (guid) => axios.post("Domain/Delete", null, { params: { guid: guid } });

// Type
export const createType = (params) => axios.post("Type/Create", params);
export const getTypes = () => axios.get("Type/GetAll");
export const getTypesByDomain = (guid) => axios.get("Type/GetAllByDomain", {params: {guid: guid}});
export const updateType = (params) => axios.post("Type/Update", params);
export const deleteType = (guid) => axios.post("Type/Delete", null, { params: { guid: guid } });

// Category
export const createCategory = (params) => axios.post("Category/Create", params);
export const getCategories = () => axios.get("Category/GetAll");
export const getCategoriesWithChilds = () => axios.get("Category/GetAllWithChilds");
export const getCategoriesWithChildsForCurrentDomain = () => axios.get("Category/GetAllWithChildsForCurrentDomain");
export const updateCategory = (params) => axios.post("Category/Update", params);
export const deleteCategory = (guid) => axios.post("Category/Delete", null, { params: { guid: guid } });

// Activity
export const createActivity = (params) => axios.post("Activity/Create", params);
export const getActivities = () => axios.get("Activity/GetAll");
export const updateActivity = (params) => axios.post("Activity/Update", params);
export const deleteActivity = (guid) => axios.post("Activity/Delete", null, { params: { guid: guid } });

  


// Zorg profiles
export const createZorgProfile = (params) => axios.post("ZorgProfile/Create", params);
export const getAvailableZpForTeam = (guid) => axios.get("ZorgProfile/GetAvailableZpForTeam", {params: {guid: guid}});
export const getSystemZp = () => axios.get("ZorgProfile/GetSystemZp");
export const getZorgProfilesByDomain = (guid) => axios.get("ZorgProfile/GetAllByDomain", {params: {guid: guid}});
export const getZorgProfilesByOrg = (guid) => axios.get("ZorgProfile/GetAllByOrg", {params: {guid: guid}});
export const updateZorgProfile = (params) => axios.post("ZorgProfile/Update",params);
export const deleteZorgProfile = (guid) => axios.post("ZorgProfile/Delete", null, { params: { guid: guid } });
export const isOrgZpExists = (sysZpGuid, locGuid, orgGuid) => axios.get("ZorgProfile/IsOrgZpExists", { params: { sysZpGuid: sysZpGuid, locGuid: locGuid, orgGuid:orgGuid} });

// Levels
export const getLevels = () => axios.get("Level/GetAll");
export const getLevelsByDomain = (guid) => axios.get("Level/GetAllByDomain", {params: {guid: guid}});
export const getLevelsForAvailableTimeByDomain = (guid) => axios.get("Level/GetForAvailableTimeByDomain", {params: {guid: guid}});
export const createLevel = (params) => axios.post("Level/Create", params);
export const updateLevel = (params) => axios.post("Level/Update", params);
export const deleteLevel = (id) => axios.post("Level/Delete", null, { params: { guid: id } });


// Costs
export const getCosts = () => axios.get("Cost/GetAll");
export const getCostsByDomain = (guid) => axios.get("Cost/GetAllByDomain", {params: {guid: guid}});
export const createCost = (params) => axios.post("Cost/Create", params);
export const updateCost = (params) => axios.post("Cost/Update", params);
export const deleteCost = (id) => axios.post("Cost/Delete", null, { params: { guid: id } });


// UnitActivity
export const getUnitActivities = () => axios.get("UnitActivity/GetAll");
export const getAgendaRows = (filter) => axios.post("UnitActivity/GetAgendaRows", filter);
export const createUnitActivity = (params) => axios.post("UnitActivity/Create", params);
export const updateUnitActivity = (params) => axios.post("UnitActivity/Update", params);
export const deleteUnitActivity = (id) => axios.post("UnitActivity/Delete", null, { params: { guid: id } });
export const getAgendaRowsForStartPage = (teamGuid) => axios.get("UnitActivity/GetAgendaRowsForStartPage", {params: {teamGuid:teamGuid}});

// UnitForms
export const getUnitForms = (filter) => axios.get("Ocr/GetUnitForms", {params: filter})
export const createUnitForm = (params) => axios.post("Ocr/CreateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data'}})
export const getFormData = (clean) => axios.get("Ocr/GetOcrData", {params:{clean:clean}})
export const deleteUnitForm = (id) => axios.post("Ocr/DeleteUnitForm", null, {params : {guid: id}});
export const getOcrResults = (clean) => axios.get("Ocr/GetOcrData", {params: {clean: clean}})

// Theme
export const getThemes = () => axios.get("Theme/GetAll");
export const createThemeWithUpload = (params) => axios.post("Theme/CreateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data'}});
export const updateThemeWithUpload = (params) => axios.post("Theme/UpdateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data'}});
export const deleteTheme = (guid) => axios.post("Theme/Delete", null, { params: { guid: guid } });

// Kanban
export const getKanbanData = (locationGuid) => axios.get("KanbanBoard/GetKanbanData", {params: {locationGuid: locationGuid}})
export const moveTask = (params) => axios.post("KanbanBoard/MoveTask", params);
export const reorderTasks = (params) => axios.post("KanbanBoard/ReorderTasks", params);
export const getTaskHistory = (taskGuid) => axios.get("KanbanBoard/GetTaskHistory", {params: {taskGuid: taskGuid}})
export const getBoardsForTeam = (guid) => axios.get("KanbanBoard/GetBoardsForTeam", {params: {guid: guid}})
export const getToDoKanbanBoard = (id, linkToReport) => axios.get("KanbanBoard/GetToDoKanbanBoard", {params: {teamId: id, linkToReport: linkToReport}})

// Kanban-task
export const createTask = (params) => axios.post("KanbanTask/Create", params)
export const createTaskWithUpload = (params) => axios.post("KanbanTask/CreateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data' } });
export const updateTask = (params) => axios.post("KanbanTask/Update", params)
export const updateTaskWithUpload = (params) => axios.post("KanbanTask/UpdateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data' } });
export const deleteTask = (guid) => axios.post("KanbanTask/Delete", null, { params: { guid: guid } });

// Report
export const getPowerBiEmbedToken = (groupId, reportId) => axios.get("Report/GetEmbedToken", {params: {groupId: groupId, reportId:reportId}})
//export const getPowerBiAccessToken = () => axios.get("Report/GetAccessToken")

export const downloadPdfReport = (daysOfWeek, unitName, currentTeamGuid) => 
axios.get("Report/GetPdfUnitWeekReport", {responseType: 'arraybuffer', params: {daysOfWeek: daysOfWeek, unitName:unitName, currentTeamGuid:currentTeamGuid}})

// Settings
export const getSetting = (key) => axios.get("Settings/GetSetting", {params: {key: key}})
export const updateSetting = (params) => axios.post("Settings/UpdateSetting", params)

// InfoPage
export const getContentByUrl = (url) => axios.get("InfoPage/GetByUrl", {params : {url: url}})
export const uploadImageForPage = (params) => axios.post("InfoPage/UploadImageForPage", params, { headers: { 'Content-Type': 'multipart/form-data' } });

export const getInfoPages = () => axios.get("InfoPage/GetAllInfoPages");
export const getInfoPageRoutes = () => axios.get("InfoPage/GetInfoPageRoutes");

export const createInfoPage = (params) => axios.post("InfoPage/Create", params);
export const updateInfoPage = (params) => axios.post("InfoPage/Update", params);
export const deleteInfoPage = (id) => axios.post("InfoPage/Delete", null, { params: { guid: id } });


// Available resources
export const createAvailableTimeTable = (params) => axios.post("AvailableTime/Create", params);
export const updateAvailableTimeTable = (params) => axios.post("AvailableTime/Update", params);
export const getAvailableTimeForTeam = (guid) => axios.get("AvailableTime/GetAvailableTimeForTeam", {params: {guid: guid}})

// Chat
export const sendTileMessage = (params, onProgress) => axios.post("Chat/SendTileMessage", params, { headers: { 'Accept': 'text/event-stream'}, responseType: 'stream', onDownloadProgress: onProgress });
export const sendTileMessageToFlowise = (params, onProgress) => axios.post("Chat/SendTileMessageToFlowise", params, { headers: { 'Accept': 'text/event-stream'}, responseType: 'stream', onDownloadProgress: onProgress });


// Dialog Tree Tiles
export const getDialogTree = (orgGuid) => axios.get("Tile/GetDialogTree", {params: {orgGuid: orgGuid}})
export const createTileWithUpload = (params) => axios.post("Tile/CreateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data'}});
export const updateTileWithUpload = (params) => axios.post("Tile/UpdateWithUpload", params, { headers: { 'Content-Type': 'multipart/form-data'}});
export const deleteTile = (guid) => axios.post("Tile/Delete", null, { params: { guid: guid } });
export const moveTileUp = (params) => axios.post("Tile/MoveUp", null, { params: params });
export const moveTileDown = (params) => axios.post("Tile/MoveDown", null, { params: params });
export const getChatFlows = () => axios.get("Tile/GetChatFlows")

// user side
export const getTiles = (parentGuid) => axios.get("Tile/GetTiles", {params:  {parentGuid: parentGuid} });



