import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { FormRow } from '../../components/FormRow';
import  Grid  from '@mui/material/Grid';
import  IconButton  from '@mui/material/IconButton';
import  CloseIcon  from '@mui/icons-material/Close';
import { BaseDialog } from './BaseDialog';
import UploadAvatar from '../../components/UploadAvatar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

export class TileDialog extends BaseDialog {
    constructor(props) {
        super(props, {
            name: '',
            prompt: '',
            chatFlowId: null,
            file: null
        });
    }

    onNameChange = (evt) => this.setState({ name: evt.currentTarget.value });
    currentName = () => this.currentProp('name','Name', '')

    onPromptChange = (evt) => this.setState({ prompt: evt.currentTarget.value });
    currentPrompt = () => this.currentProp('prompt','Prompt', '')

    onChatFlowChange = (evt) => this.setState({ chatFlowId: evt.target.value });
    currentChatFlowId = () => {
        var chatFlowId = this.currentProp('chatFlowId', 'ChatFlowId', this.getFirstChatFlowId())
        if (!chatFlowId) { // old existing tiles has null chatFlowId, show first
            return this.getFirstChatFlowId()
        }

        return chatFlowId;
    }
    getFirstChatFlowId = () => this.props.chatFlows?.length > 0 ? this.props.chatFlows[0].Id : null
   
    onOkClick = async () => {
        await this.props.hideDialog();
        this.onDialogClose({ name: this.currentName(), prompt: this.currentPrompt(), chatFlowId: this.currentChatFlowId() }, false)
    } 
    onCancelClick = () => this.onDialogClose({}, true);

    onSelectFile = (f) => {
        this.setState({ file: f })
    }

    onPreviewLoaded = (p) => {
        this.setState({preview: p})
    }

    render = () => {
        return (
            <Dialog open={this.props.open} fullWidth maxWidth="md">
                <DialogTitle>
                    <Grid container>
                        <Grid item xs={10} style={{ alignSelf: 'center' }}>{this.props.currentItem ? "Tile bewerken" : "Nieuw tile"}</Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <IconButton onClick={this.onCancelClick}><CloseIcon /></IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <FormRow>
                            <TextField
                                fullWidth
                                name="Name"
                                required
                                label="Naam"
                                defaultValue={this.currentName()}
                                onChange={this.onNameChange} />
                        </FormRow>
                        <FormRow>
                            <TextField
                                fullWidth
                                name="Prompt"
                                required
                                label="Prompt"
                                defaultValue={this.currentPrompt()}
                                onChange={this.onPromptChange} />
                        </FormRow>
                        <FormRow>
                            <Select fullWidth
                                value={this.currentChatFlowId()}
                                onChange={(e) => this.onChatFlowChange(e)}
                            >
                                {this.props.chatFlows.map((c) => <MenuItem key={c.Id} value={c.Id}>{c.Name}</MenuItem>)}
                            </Select>
                            <FormHelperText>Flowise Chatflow</FormHelperText>                            
                        </FormRow>
                        <FormRow>
                            <UploadAvatar selectedFile={this.state.file} onSelectFile={this.onSelectFile} currentItem={this.props.currentItem} onPreviewLoaded={this.onPreviewLoaded} />
                        </FormRow>                        
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.onOkClick()} color="primary">OPSLAAN</Button>
                    <Button onClick={() => this.onCancelClick()} color="primary">TERUG</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
